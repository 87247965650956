import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Profile } from '../pages/profile';
// import BusinessCard from '../pages/business-cards';

const BusinessCard = lazy(() => import('../pages/business-cards'));

const Routers: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BusinessCard />
            </Suspense>
          }
        />
        <Route
          path="/profile/:slug"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Profile />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default Routers;