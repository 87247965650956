import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PROFILE } from '../../constants/api';
import { MasterAPICall } from '../../api/master.api';
import { GrayTemplate } from '../templates/gray';
import { Template2 } from '../templates/template2';

export const Profile = () => {
    const [data, setData] = useState<any>(null);
    const params = useParams();
    useEffect(() => {
        getProfileData()
    }, [])

    const getProfileData = () => {
        const api_url = PROFILE + '/' + params.slug;
        MasterAPICall(api_url, 'get', '').then((response: any) => {
            setData(response.data)
        }).catch((error: Error) => {
            console.log(error)
        })
    }
    return (
        <React.Fragment>
            {
                data?.template === "template2" ?
                <Template2 data={data} />
                :
                <GrayTemplate data={data} />
            }
            {/* <GrayTemplate data={data} /> */}
        </React.Fragment>
    )
}
