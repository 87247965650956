import React, { useState } from 'react';
import { Images } from '../../../components/images';
import { BASE_URL_API, IMAGE_PATH } from '../../../constants/api';
import { IConnectType, ITemplate } from '../../../interfaces';
import { handleDownloadContact, renderContacts } from '../../../utils/helper';
import '../../business-cards/template2.css';
import ShareButtons from '../../../components/share';
import { SocialLinks } from '../../../components/social-link';

export const Template2 = ({ data }: ITemplate) => {
    const [shareEnable, toggleShare] = useState(false);
    const showContactUsSection = () => {
        return (
            <React.Fragment>
                {
                    data?.contacts && data.contacts.map((contact: any, index: number) => {
                        return contact.type === "Contact Number" ?
                            <div className="template_contact_info" key={index}>
                                <div className="template_contact_info_title" style={{ color: data?.primary_text_color }}>{contact.contact_label}</div>
                                <div className="template_contact_number">
                                    <a href={`tel:${contact.contact_value}`} style={{ color: data?.secondary_text_color }}>{contact.contact_value}</a>
                                </div>
                            </div>
                            :
                            (
                                contact.type === "Email" ?
                                    <div className="template_email_info" key={index}>
                                        <div className="template_email_info_title" style={{ color: data?.primary_text_color }}>Email</div>
                                        <div className="template_email_id">
                                            <a style={{ color: data?.secondary_text_color }} href={`mailto:${contact.contact_value}`}>{contact.contact_value}</a>
                                        </div>
                                    </div>
                                    :
                                    <div className="template_address_info" key={index}>
                                        <div className="template_address_info_title" style={{ color: data?.primary_text_color }}>{contact.contact_label}</div>
                                        <div className="template_address_text" style={{ color: data?.secondary_text_color }}>
                                            {/* {contact.contact_label} */}
                                            {contact.address_line1 && contact.address_line1 + ' '}
                                            {contact.address_line2 && contact.address_line2 + ' '}
                                            {contact.city && contact.city + ', '}
                                            {contact.state && contact.state + ', '}
                                            {contact.zipcode && contact.zipcode + ' '}
                                            {contact.country && contact.country}
                                        </div>
                                        {
                                            (contact.action_button_enable === 1 || contact.action_button_enable === true) &&
                                            <a className="template_direction_btn" style={{ background: data?.theme_primary_color }} href={contact.google_map_url} target="_blank">
                                                <span className="icon-direction_1"></span>
                                                {contact.action_button_text}
                                            </a>
                                        }
                                    </div>
                            )
                    })
                }



            </React.Fragment>
        )
    }

    let backgroundStyle: any = {
        // fontFamily: 'cursive',
        fontFamily: `${data?.font_style}`,
        background: data?.background_color
    }
    if (data?.background_image) {
        backgroundStyle = { ...backgroundStyle, background: `url(${`${IMAGE_PATH}/imgbg/${data?.background_image}`})` }
    }

    return (
        <div className="template_page_wrapper thinScrollBar" style={{ ...backgroundStyle }}>
            <div className="template_background">
            </div>
            <div className="template_page_inner">
                {
                    (data?.show_profile_section === 1 || data?.show_profile_section === true) &&
                    <div className="section qrc_profile_3">
                        <div className="template_profile_inner">
                            <div className="template_profilepic">
                                {
                                    (data?.show_profile_pic === 1 || data?.show_profile_pic === true) &&
                                    <img src={`${BASE_URL_API}${data?.profile}`} alt='Profile' />
                                }
                                <svg id="Layer_1" className="qrc_profile_3_svg" data-name="Layer 1"
                                    viewBox="0 0 375 260">
                                    <defs>

                                        <linearGradient id="linear-gradient" x1="1.06" y1="260.32" x2="1.06" y2="259.32"
                                            gradientTransform="translate(-208.5 67424) scale(375 -259)"
                                            gradientUnits="userSpaceOnUse">
                                            <stop offset="0" stop-color="#000" stop-opacity="0"></stop>
                                            <stop offset="1" stop-color="#000"></stop>
                                        </linearGradient>
                                    </defs>
                                    <rect id="Rectangle_297" data-name="Rectangle 297" className="cls-1" width="375" height="260">
                                    </rect>
                                </svg>
                            </div>
                            <div className="template_profile_inner_info">
                                <h2 style={{ color: data?.primary_profile_text }}>{data?.name}</h2>
                                <p style={{ color: data?.secondary_profile_text }}>{data?.title}</p>
                                <p style={{ color: data?.secondary_profile_text }}><strong>{data?.sub_title}</strong></p>
                                <div className="template_profile_brand_logo">
                                    {/* <img src="/images/qr-logo.jfif" /> */}
                                    {
                                        (data?.show_logo === 1 || data?.show_logo === true) &&
                                        <img src={`${BASE_URL_API}${data?.logo}`} alt='Logo' />
                                    }
                                </div>
                                {
                                    (data?.show_profile_connect_icon === 1 || data?.show_profile_connect_icon === true) &&
                                    <div className="template_profile_shortcut">
                                        <ul>
                                            {/* <li className="template_cc_card"><a href="tel:0000000000"><i className="fa fa-mobile"
                                        aria-hidden="true"></i></a></li>
                                    <li className="template_cc_card"><a href="mailto:youremail@domain.com"><i
                                        className="fa fa-envelope-o" aria-hidden="true"></i></a></li>
                                    <li className="template_cc_card"><a href="sms:0000000000"><i className="fa fa-commenting-o"
                                        aria-hidden="true"></i></a>
                                    </li> */}
                                            {
                                                data?.profile_connects && data.profile_connects.map((connect: IConnectType) => {
                                                    const html: any = renderContacts(connect);
                                                    return <li className="template_cc_card" style={{ background: data.theme_primary_color }} dangerouslySetInnerHTML={{ __html: html }} ></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {/* About */}
                {
                    (data?.show_about_section === 1 || data?.show_about_section === true) &&
                    <div className="template_heading_text template_cc_card" style={{ background: data?.box_color, borderRadius: data?.box_radius }}>
                        <h2 style={{ color: data?.primary_text_color }}>{data?.heading_title}</h2>
                        <p style={{ color: data?.secondary_text_color }}>{data?.heading_description}</p>
                    </div>
                }
                {/* contact us */}
                {
                    (data?.show_contact_us_section === 1 || data?.show_contact_us_section === true) &&
                    <div className="template_contact template_cc_card" style={{ background: data?.box_color, borderRadius: data?.box_radius }}>
                        <div className="template_contact_header">
                            {/* <div className="template_contact_hdr_img">
                                <img src={`${IMAGE_PATH}/contactus.png`} alt="" />
                            </div> */}
                            <div className="template_contact_hdr_text" style={{ color: data?.primary_text_color }}>
                                <h2>{data?.contact_title}</h2>
                            </div>
                        </div>
                        {showContactUsSection()}

                    </div>
                }

                {/* <div className="template_contact template_cc_card">
                    <div className="template_contact_header">
                        <div className="template_contact_hdr_img">
                            <img src="/images/contactus.png" alt="" />
                        </div>
                        <div className="template_contact_hdr_text">Contact Us</div>
                    </div>
                    <div className="template_contact_info">
                        <div className="template_contact_info_title">Call Us</div>
                        <div className="template_contact_number"><a href="tel:123 456 7890">123 456 7890</a></div>
                    </div>
                    <div className="template_email_info">
                        <div className="template_email_info_title">Email</div>
                        <div className="template_email_id"><a href="mailto:contactme@domain.com">contactme@domain.com</a></div>
                    </div>
                    <div className="template_address_info">
                        <div className="template_address_info_title">Address</div>
                        <div className="template_address_text">Street<br />City, State, Zipcode<br />Country</div>
                        <a className="template_direction_btn" href="//#" target="_blank"><span
                            className="icon-direction_1"></span>Direction</a>
                    </div>

                </div> */}
                {(data?.show_image_section === 1 || data?.show_image_section === true) &&
                    <div className="template_gallery template_cc_card" style={{ background: data.box_color, borderRadius: data.box_radius }}>
                        {
                            (data?.image_title || data?.image_description) &&
                            <div className="template_heading_text">
                                <h2 style={{ color: data?.primary_text_color }}>{data?.image_title}</h2>
                                <p style={{ color: data?.secondary_text_color }}>{data?.image_description}</p>
                            </div>
                        }
                        <div className="template_gallery_wrapper">
                            <Images cName="template_gallery_list" images={data?.images} data={data} />
                            {/* <ul className="template_gallery_list">
                            <li>
                                <a rel="nofollow noopener noreferrer" href="images/image_1.png" data-lightbox="images-gallery">
                                    <img className="img-fluid" src="images/image_1.png" />
                                </a>
                            </li>
                            <li>
                                <a rel="nofollow noopener noreferrer" href="images/image_2.png" data-lightbox="images-gallery">
                                    <img className="img-fluid" src="images/image_2.png" />
                                </a>
                            </li>
                            <li>
                                <a rel="nofollow noopener noreferrer" href="images/image_1.png" data-lightbox="images-gallery">
                                    <img className="img-fluid" src="images/image_1.png" />
                                </a>
                            </li>
                            <li>
                                <a rel="nofollow noopener noreferrer" href="images/image_2.png" data-lightbox="images-gallery">
                                    <img className="img-fluid" src="images/image_2.png" />
                                </a>
                            </li>
                        </ul> */}
                        </div>
                    </div>
                }
                {
                    (data?.show_social_section === 1 || data?.show_social_section === true) && <SocialLinks data={data} />
                }
            </div>
            {
                data?.slug &&
                <div className="col-md-6 d-flex justify-content-end">
                    <button className="btn floating_contact_button"
                        style={{ background: data?.theme_primary_color }}
                        onClick={() => handleDownloadContact(data)}
                        type="button">
                        <span className="floating_contact_button_text">{data?.contact_button_text}</span>
                        <span className="add_contact_icon"><i className="fa fa-plus" aria-hidden="true"></i></span>
                    </button>
                </div>
            }
            {
                data?.slug &&
                <div className="shareicons-template2">
                    {
                        shareEnable && <ShareButtons title={data.title} url={data.slug} data={data} themeBackground="#333333" />
                    }

                    <div className="template_button_wrapper">
                        <div style={{ display: "flex" }}>
                            {/* <button className="btn " id="btn_page_qr_code"><i className="fa fa-qrcode" aria-hidden="true"></i></button> */}
                            <button onClick={() => toggleShare(!shareEnable)} style={{ background: data?.theme_primary_color }} className="btn" id="btn_share_page"><i className="fa fa-upload" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
