import axios from 'axios';
import { BASE_URL_API } from '../constants/api';

export const Api: any = axios.create({
  baseURL: BASE_URL_API + '/api',
  timeout: 10000,
  // headers: { 'Content-Type': 'application/json' }
});

Api.interceptors.request.use((config: any) => {
  const accessToken = localStorage.getItem("auth_token");

  //checking if accessToken exists
  // if (accessToken) {
  //   config.headers["Authorization"] = accessToken;
  // }

  return config;
});
