import { BASE_URL_API, WEBSITE_LINK } from "../constants/api";
import { IConnectType } from "../interfaces";

export const renderContacts = (connect: IConnectType) => {
    let connectHtml;
    switch (connect.connect_type) {
        case "Mobile":
            connectHtml = `<a href="tel:${connect.connect_value}"><i class="fa fa-mobile" aria-hidden="true"></i></a>`;
            break;
        case "Email":
            connectHtml = `<a href="mailto:${connect.connect_value}"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>`;
            break;
        case "SMS":
            connectHtml = `<a href="sms:${connect.connect_value}"><i class="fa fa-commenting-o" aria-hidden="true"></i></a>`;
            break;

        default:
            break;
    }
    return connectHtml;
}

export const handleShare = async (data: any) => {
    try {
        if (data.slug && navigator.share) {
            await navigator.share({
                title: data.title,
                text: data.sub_title,
                url: WEBSITE_LINK + '/profile/' + data.slug,
            });
        }
    } catch (error) {
        console.error('Error sharing:', error);
    }
};

export const handleDownload = async (imagePath: any) => {
    // const imagePath = 'http://64.227.186.5:9091/storage/qrcodes/qr-1712058747.png'; // Replace 'path_to_your_image.jpg' with your actual image path

    try {
        const apiUrl = BASE_URL_API + '/api/qrcode/download?image_path=' + imagePath;
        const response = await fetch(apiUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'qr-code.png');

        // Simulate click on the link
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    } catch (error) {
        console.error(error);
    }
}

export const handleDownloadOnProd = (imagePath: any) => {
    // const splitPath = imagePath.split("public")[1];
    // const imageUrl = BASE_URL_API + splitPath // Replace with your image URL
    // const imageName = imagePath.split("qrcode/")[1];
    // const fileName = imageName // Desired file name

    // Create a virtual anchor element
    const link = document.createElement('a');
    link.href = imagePath;
    link.setAttribute('download', 'qr-code.png');

    // Simulate click on the link
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
};

export const renderURL = (url: any) => {
    const hasProtocol = /^https?:\/\//i.test(url);
    if (!hasProtocol) {
        return `https://${url}`;
    }
    return url;
};

export const handleDownloadContact = (data: any) => {
    const nameObj = data.contacts.find((item: any) => item.type === "Contact Number");
    const emailObj = data.contacts.find((item: any) => item.type === "Email");
    const addrObj = data.contacts.find((item: any) => item.type === "Address");
    const vCardData =
        `BEGIN:VCARD
VERSION:3.0
N;CHARSET=UTF-8:;${data.name};;;
FN;CHARSET=UTF-8:${data.name}
ORG;CHARSET=UTF-8:${data.sub_title}
TITLE;CHARSET=UTF-8:${data.title}
item1.TEL;type=${nameObj.contact_label}:${nameObj.contact_value}
item1.X-ABLabel;CHARSET=UTF-8:${nameObj.contact_label}
item2.EMAIL;type=${emailObj.contact_label}:${emailObj.email}
item2.X-ABLabel;CHARSET=UTF-8:${emailObj.contact_label}
item3.ADR;CHARSET=UTF-8:;${addrObj.address_line1};;${addrObj.city};${addrObj.state};${addrObj.zipcode};${addrObj.country}
item3.X-ABLabel;CHARSET=UTF-8:${addrObj.contact_label}
item3.X-ABADR:country
item5.X-ABLabel;CHARSET=UTF-8:Title
END:VCARD`;
    const blob = new Blob([vCardData], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${data.name}.vcf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);


    // item6.URL;type=facebook:${contact.socialMedia.facebook}
    // item6.X-ABLabel;CHARSET=UTF-8:Facebook
    // item7.URL;type=instagram:${contact.socialMedia.instagram}
    // item7.X-ABLabel;CHARSET=UTF-8:Instagram
    // item8.URL;type=twitter:${contact.socialMedia.twitter}
    // item8.X-ABLabel;CHARSET=UTF-8:Twitter
    // NOTE;CHARSET=UTF-8:${contact.description}
}