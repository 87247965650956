import React, { useState } from 'react';
import '../../business-cards/style.css'
import { IConnectType, ISocialType, ITemplate } from '../../../interfaces';
import { BASE_URL_API, IMAGE_PATH } from '../../../constants/api';
import { handleDownloadContact, handleShare, renderContacts } from '../../../utils/helper';
import { Images } from '../../../components/images';
import ShareButtons from '../../../components/share';
import { SocialLinks } from '../../../components/social-link';

export const GrayTemplate = ({ data }: ITemplate) => {
    const [shareEnable, toggleShare] = useState(false)
    /*const renderContacts = (connect: IConnectType) => {
        let connectHtml;
        switch (connect.connect_type) {
            case "Mobile":
                connectHtml = `<a href="tel:${connect.connect_value}"><i class="fa fa-mobile" aria-hidden="true"></i></a>`;
                break;
            case "Email":
                connectHtml = `<a href="mailto:${connect.connect_value}"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>`;
                break;
            case "SMS":
                connectHtml = `<a href="sms:${connect.connect_value}"><i class="fa fa-commenting-o" aria-hidden="true"></i></a>`;
                break;

            default:
                break;
        }
        return connectHtml;
    }*/

    const showContactUsSection = () => {
        return (
            <React.Fragment>
                {
                    data?.contacts && data.contacts.map((contact: any, ind: number) => {
                        return contact.type === "Contact Number" ?
                            <div className="qrc_contact_info" key={ind}>
                                <div className="qrc_contact_info_title" style={{ color: data?.primary_text_color }}>{contact.contact_label}</div>
                                <div className="qrc_contact_number">
                                    <a style={{ color: data?.secondary_text_color }} href={`tel:${contact.contact_value}`}>{contact.contact_value}</a>
                                </div>
                            </div>
                            :
                            (
                                contact.type === "Email" ?
                                    <div className="qrc_email_info" key={ind}>
                                        <div className="qrc_email_info_title" style={{ color: data?.primary_text_color }}>Email</div>
                                        <div className="qrc_email_id" style={{ color: data?.secondary_text_color }}><a href={`mailto:${contact.contact_value}`}>{contact.contact_value}</a></div>
                                    </div>
                                    :
                                    <div className="qrc_address_info" key={ind}>
                                        <div className="qrc_address_info_title" style={{ color: data?.primary_text_color }}>{contact.contact_label}</div>
                                        <div className="qrc_address_text" style={{ color: data?.secondary_text_color }}>
                                            {/* {contact.contact_label} */}
                                            {contact.address_line1 && contact.address_line1 + ' '}
                                            {contact.address_line2 && contact.address_line2 + ' '}
                                            {contact.city && contact.city + ', '}
                                            {contact.state && contact.state + ', '}
                                            {contact.zipcode && contact.zipcode + ' '}
                                            {contact.country && contact.country}
                                        </div>
                                        {
                                            (contact.action_button_enable === 1 || contact.action_button_enable === true) &&
                                            <a rel="noreferrer" className="qrc_direction_btn" style={{ background: data?.theme_primary_color }} href={contact.google_map_url} target="_blank">
                                                <span className="icon-direction_1"></span>
                                                {contact.action_button_text}
                                            </a>
                                        }
                                    </div>
                            )
                    })
                }



            </React.Fragment>
        )
    }

    // const handleShare = async () => {
    //     try {
    //         if (data.slug && navigator.share) {
    //             await navigator.share({
    //                 title: data.title,
    //                 text: data.sub_title,
    //                 url: WEBSITE_LINK + '/profile/' + data.slug,
    //             });
    //         }
    //     } catch (error) {
    //         console.error('Error sharing:', error);
    //     }
    // };

    let backgroundStyle: any = {
        // fontFamily: 'cursive',
        fontFamily: `${data?.font_style}`,
        background: data?.background_color
    }
    if (data?.background_image) {
        backgroundStyle = { ...backgroundStyle, background: `url(${`${IMAGE_PATH}/imgbg/${data?.background_image}`})` }
    }

    // const saveData = () => {
    //     //set properties
    //     vCard.firstName = 'Eric';
    //     vCard.middleName = 'J';
    //     vCard.lastName = 'Nesser';
    //     vCard.email = 'ACME Corporation';
    //     vCard.workPhone = '312-555-1212';

    //     //save to file
    //     vCard.saveToFile('./eric-nesser.vcf');
    // }

    return (

        <div className="qrc_page_wrapper thinScrollBar" style={{ ...backgroundStyle }}>
            <div className="qrc_page_inner">
                {
                    (data?.show_profile_section === 1 || data?.show_profile_section === true) &&
                    <div className="section qrc_profile_5">
                        {
                            (data?.show_logo === 1 || data?.show_logo === true) &&
                            <div className="qrc_profile_brand_logo">
                                {/* <img src={`${IMAGE_PATH}/qr-logo.png`} alt='Logo' /> */}
                                <img src={`${BASE_URL_API}${data?.logo}`} alt='Logo' />
                            </div>
                        }
                        <div className="qrc_profile_inner" style={{ backgroundColor: data?.theme_secondary_color }}>
                            {
                                (data?.show_profile_pic === 1 || data?.show_profile_pic === true) &&
                                <div className="qrc_profilepic">
                                    {/* <img src={`${IMAGE_PATH}/profile_2.webp`} alt="" /> */}
                                    <img src={`${BASE_URL_API}${data?.profile}`} alt='Profile' />
                                </div>
                            }
                            <div className="qrc_profile_inner_info">
                                <h2 style={{ color: data?.primary_profile_text }}>{data?.name}</h2>
                                <p style={{ color: data?.secondary_profile_text }}>{data?.title}</p>
                                <p style={{ color: data?.secondary_profile_text }}><strong>{data?.sub_title}</strong></p>
                            </div>
                        </div>
                        {
                            (data?.show_profile_connect_icon === 1 || data?.show_profile_connect_icon === true) &&
                            <div className="qrc_profile_shortcut" style={{ background: data?.theme_primary_color, borderRadius: data.box_radius }}>
                                <ul>
                                    {
                                        data?.profile_connects && data.profile_connects.map((connect: IConnectType) => {
                                            const html: any = renderContacts(connect);
                                            return <li className="qr_cc_card" style={{ background: data?.theme_primary_color }} dangerouslySetInnerHTML={{ __html: html }} ></li>
                                        })
                                    }
                                    {/* <li className="qr_cc_card"><a href="tel:0000000000"><i className="fa fa-mobile" aria-hidden="true"></i></a></li>
                                        <li className="qr_cc_card"><a href="mailto:youremail@domain.com"><i className="fa fa-envelope-o" aria-hidden="true"></i></a></li>
                                        <li className="qr_cc_card"><a href="sms:0000000000"><i className="fa fa-commenting-o" aria-hidden="true"></i></a></li> */}
                                </ul>
                            </div>
                        }
                    </div>
                }
                {/* About */}
                {
                    (data?.show_about_section === 1 || data?.show_about_section === true) &&
                    <div className="section qrc_heading_text qr_cc_card" style={{ background: data?.box_color, borderRadius: data?.box_radius }}>
                        <h2 style={{ color: data?.primary_text_color }}>{data?.heading_title}</h2>
                        <p style={{ color: data?.secondary_text_color }}>{data?.heading_description}</p>
                    </div>
                }
                {/* contact us */}
                {
                    (data?.show_contact_us_section === 1 || data?.show_contact_us_section === true) &&
                    <div className="section qrc_contact qr_cc_card" style={{ background: data?.box_color, borderRadius: data?.box_radius }}>
                        <div className="qrc_contact_header">
                            {/* <div className="qrc_contact_hdr_img">
                                <img src={`${IMAGE_PATH}/contactus.png`} alt="" />
                            </div> */}
                            <div className="qrc_contact_hdr_text" style={{ color: data?.primary_text_color }}>
                                <h2>{data?.contact_title}</h2>
                            </div>
                        </div>
                        {showContactUsSection()}
                        {/* <div className="qrc_contact_info">
                                <div className="qrc_contact_info_title">Call Us</div>
                                <div className="qrc_contact_number"><a href="tel:123 456 7890">123 456 7890</a></div>
                            </div>
                            <div className="qrc_email_info">
                                <div className="qrc_email_info_title">Email</div>
                                <div className="qrc_email_id"><a href="mailto:contactme@domain.com">contactme@domain.com</a></div>
                            </div>
                            <div className="qrc_address_info">
                                <div className="qrc_address_info_title">Address</div>
                                <div className="qrc_address_text">Street City, State, Zipcode Country</div>
                                <a className="qrc_direction_btn" href="//#" target="_blank"><span
                                    className="icon-direction_1"></span>Direction</a>
                            </div> */}

                    </div>
                }

                {(data?.show_image_section === 1 || data?.show_image_section === true) &&
                    <div className="section qrc_gallery qr_cc_card" style={{ background: data?.box_color, borderRadius: data?.box_radius }}>
                        {
                            (data?.image_title || data?.image_description) &&
                            <div className="qrc_heading">
                                <h2 style={{ color: data?.primary_text_color }}>{data?.image_title}</h2>
                                <p style={{ color: data?.secondary_text_color }}>{data?.image_description}</p>
                            </div>
                        }
                        <div className="qrc_gallery_wrapper">
                            <Images cName="qrc_gallery_list" images={data?.images} data={data} />
                            {/* <ul className="qrc_gallery_list">
                                <li>
                                    <a rel="nofollow noopener noreferrer" href={`${IMAGE_PATH}/image_1.png`} data-lightbox="images-gallery">
                                        <img className="img-fluid" src={`${IMAGE_PATH}/image_1.png`} />
                                    </a>
                                </li>
                                <li>
                                    <a rel="nofollow noopener noreferrer" href={`${IMAGE_PATH}/image_2.png`} data-lightbox="images-gallery">
                                        <img className="img-fluid" src={`${IMAGE_PATH}/image_2.png`} />
                                    </a>
                                </li>
                                <li>
                                    <a rel="nofollow noopener noreferrer" href={`${IMAGE_PATH}/image_1.png`} data-lightbox="images-gallery">
                                        <img className="img-fluid" src={`${IMAGE_PATH}/image_1.png`} />
                                    </a>
                                </li>
                                <li>
                                    <a rel="nofollow noopener noreferrer" href={`${IMAGE_PATH}/image_2.png`} data-lightbox="images-gallery">
                                        <img className="img-fluid" src={`${IMAGE_PATH}/image_2.png`} />
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                }
                {
                    <SocialLinks data={data} />
                }
                {/* <div className="section qrc_social_links">
                    <ul className="qrc_social_links_list">
                        {
                            (data && data?.social_links) &&
                            data.social_links.map((item: ISocialType) => {
                                return (
                                    <React.Fragment>
                                        <li className="qr_cc_card">
                                            <div className="qrc_heading"><h2>{data.social_title}</h2> <p>{data.social_description}</p> </div>
                                            <a rel="nofollow noopener noreferrer social_link_a" href={item.url} target="_blank">
                                                <div className="qrc_social_icon">
                                                    <img src={`${IMAGE_PATH}/fb_icon.png`} alt={item.type} />
                                                </div>
                                                <div className="qrc_social_text">
                                                    <div className="qrc_social_text_heading">{item.title || item.url}</div>
                                                    <div className="qrc_social_text_discription">{item.show_sub_title || ''}</div>
                                                </div>
                                                <div className="qrc_social_action">
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="qr_cc_card">
                                            <a rel="nofollow noopener noreferrer social_link_a" href={item.url} target="_blank">
                                                <div className="qrc_social_icon" >
                                                    <img src={`${IMAGE_PATH}/insta_icon.png`} alt={item.type} />
                                                </div>
                                                <div className="qrc_social_text">
                                                    <div className="qrc_social_text_heading">{item.title || item.url}</div>
                                                    <div className="qrc_social_text_discription">{item.show_sub_title || ''}</div>
                                                </div>
                                                <div className="qrc_social_action">
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </li>
                                    </React.Fragment>
                                )
                            })
                        }
                        <li className="qr_cc_card">
                            <div className="qrc_heading"><h2>Social Links</h2> <p>Description</p> </div>
                            <a rel="nofollow noopener noreferrer social_link_a" href={'ytef'} target="_blank">
                                <div className="qrc_social_icon">
                                    <img src={`${IMAGE_PATH}/fb_icon.png`} alt='Facebook' />
                                </div>
                                <div className="qrc_social_text">
                                    <div className="qrc_social_text_heading">Facebook</div>
                                    <div className="qrc_social_text_discription">Follow us on Facebook</div>
                                </div>
                                <div className="qrc_social_action">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                            </a>
                        </li>
                        <li className="qr_cc_card">
                            <a rel="nofollow noopener noreferrer social_link_a" href="#" target="_blank">
                                <div className="qrc_social_icon" >
                                    <img src={`${IMAGE_PATH}/insta_icon.png`} />
                                </div>
                                <div className="qrc_social_text">
                                    <div className="qrc_social_text_heading">Instagram</div>
                                    <div className="qrc_social_text_discription">gfhfghfghgf</div>
                                </div>
                                <div className="qrc_social_action">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                            </a>
                        </li>

                        <li className="qr_cc_card">

                            <a rel="nofollow noopener noreferrer social_link_a" href="#" target="_blank">
                                <div className="qrc_social_icon">
                                    <img src={`${IMAGE_PATH}/linkedin_icon.png`} />
                                </div>
                                <div className="qrc_social_text">
                                    <div className="qrc_social_text_heading">LinkedIn</div>
                                    <div className="qrc_social_text_discription">Follow us</div>
                                </div>
                                <div className="qrc_social_action">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                            </a>
                        </li>
                        <li className="qr_cc_card">

                            <a rel="nofollow noopener noreferrer social_link_a" href="#" target="_blank">
                                <div className="qrc_social_icon">
                                    <img src={`${IMAGE_PATH}/tw_icon.png`} />
                                </div>
                                <div className="qrc_social_text">
                                    <div className="qrc_social_text_heading">Twitter</div>
                                    <div className="qrc_social_text_discription">Follow us on Twitter</div>
                                </div>
                                <div className="qrc_social_action">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                            </a>
                        </li>
                        <li className="qr_cc_card">

                            <a rel="nofollow noopener noreferrer social_link_a" href="#" target="_blank">
                                <div className="qrc_social_icon">
                                    <img src={`${IMAGE_PATH}/skype_icon.png`} />
                                </div>
                                <div className="qrc_social_text">
                                    <div className="qrc_social_text_heading">Skype</div>
                                    <div className="qrc_social_text_discription">Follow us</div>
                                </div>
                                <div className="qrc_social_action">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                            </a>
                        </li>
                        <li className="qr_cc_card">

                            <a rel="nofollow noopener noreferrer social_link_a" href="#" target="_blank">
                                <div className="qrc_social_icon">
                                    <img src={`${IMAGE_PATH}/whatsapp_icon.png`} />
                                </div>
                                <div className="qrc_social_text">
                                    <div className="qrc_social_text_heading">Whatsapp</div>
                                    <div className="qrc_social_text_discription">Follow us</div>
                                </div>
                                <div className="qrc_social_action">
                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div> */}
                {/*             
            <div className="section qrc_social_links">
                <ul className="qrc_social_links_list">
                    <li className="qr_cc_card">
                        <div className="qrc_heading">
                            <h2>Web Links</h2>
                            <p>Description</p>
                        </div>
                        <a rel="nofollow noopener noreferrer" href="" target="_blank">
                            <div className="qrc_social_icon"
                                style="background-image:url('weblink.png');"></div>
                            <div className="qrc_social_text">
                                <div className="qrc_social_text_heading">Title</div>
                                <div className="qrc_social_text_discription">Sub Title</div>
                            </div>
                            <div className="qrc_social_action">
                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>  */}
            </div>
            {data?.slug &&
                <div className="col-md-6 d-flex justify-content-end" >
                    <button className="btn floating_contact_button"
                        style={{ background: data?.theme_primary_color }}
                        type="button" onClick={() => handleDownloadContact(data)}>
                        <span className="floating_contact_button_text">{data?.contact_button_text}</span>
                        <span className="add_contact_icon"><i className="fa fa-plus" aria-hidden="true"></i></span>
                    </button>
                </div>
            }
            {
                data?.slug &&
                <div className="shareicons">
                    {
                        shareEnable && <ShareButtons title={data?.title} url={data?.slug} data={data} themeBackground="#fb5728" />
                    }

                    <div className="extra_button_wrapper">
                        <div style={{ display: "flex" }}>
                            {/* <button className="btn " id="btn_page_qr_code"><i className="fa fa-qrcode" aria-hidden="true"></i></button> */}
                            <button onClick={() => toggleShare(!shareEnable)} style={{ background: data?.theme_primary_color }} className="btn " id="btn_share_page"><i className="fa fa-upload" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            }
        </div >



    )
}
