import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share';
import { WEBSITE_LINK } from '../constants/api';
interface IProps {
    url: string;
    title: string;
    data?: any;
    themeBackground: any;
}

export function ShareButtons({ url, title, data, themeBackground }: IProps) {
    const shareUrl = WEBSITE_LINK + '/profile/' + url;
    return (
        <div>
             <WhatsappShareButton className='facebook-share' style={{background: `${data?.theme_primary_color || themeBackground}`}} url={shareUrl} title={title}>
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
            </WhatsappShareButton>
            <FacebookShareButton className='facebook-share' style={{background: `${data?.theme_primary_color || themeBackground}`}} url={shareUrl} title={title}>
                <i className="fa fa-facebook" aria-hidden="true"></i>
            </FacebookShareButton>
            <TwitterShareButton className='facebook-share' style={{background: `${data?.theme_primary_color || themeBackground}`}} url={shareUrl} title={title}>
                <i className="fa fa-twitter" aria-hidden="true"></i>
            </TwitterShareButton>
            <LinkedinShareButton className='facebook-share' style={{background: `${data?.theme_primary_color || themeBackground}`}} url={shareUrl} title={title}>
                <i className="fa fa-linkedin" aria-hidden="true"></i>
            </LinkedinShareButton>
            <TelegramShareButton className='facebook-share' style={{background: `${data?.theme_primary_color || themeBackground}`}} url={shareUrl} title={title}>
                <i className="fa fa-telegram" aria-hidden="true"></i>
            </TelegramShareButton>
        </div>
    );
}

export default ShareButtons;