import { BASE_URL_API } from "../constants/api"

export const Images = ({ cName, images, data }: any) => {
    return (
        <ul className={cName} style={{  borderRadius: data?.box_radius }}>
            {
                images && images.map((image: any, index: number) => {
                    return (
                        <li key={index}>
                            <a rel="nofollow noopener noreferrer" href={`${BASE_URL_API}${image}`} data-lightbox="images-gallery">
                                <img className="img-fluid" src={`${BASE_URL_API}${image}`} alt={image} />
                            </a>
                        </li>
                    )
                })
            }
            {/* <li>
                <a rel="nofollow noopener noreferrer" href={`${IMAGE_PATH}/image_1.png`} data-lightbox="images-gallery">
                    <img className="img-fluid" src={`${IMAGE_PATH}/image_1.png`} />
                </a>
            </li>
            <li>
                <a rel="nofollow noopener noreferrer" href={`${IMAGE_PATH}/image_2.png`} data-lightbox="images-gallery">
                    <img className="img-fluid" src={`${IMAGE_PATH}/image_2.png`} />
                </a>
            </li>
            <li>
                <a rel="nofollow noopener noreferrer" href={`${IMAGE_PATH}/image_1.png`} data-lightbox="images-gallery">
                    <img className="img-fluid" src={`${IMAGE_PATH}/image_1.png`} />
                </a>
            </li>
            <li>
                <a rel="nofollow noopener noreferrer" href={`${IMAGE_PATH}/image_2.png`} data-lightbox="images-gallery">
                    <img className="img-fluid" src={`${IMAGE_PATH}/image_2.png`} />
                </a>
            </li> */}
        </ul>
    )
}
