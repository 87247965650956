import React from 'react'
import { ISocialType } from '../interfaces'
import { IMAGE_PATH } from '../constants/api'
import { renderURL } from '../utils/helper'

export const SocialLinks = ({ data }: any) => {
    const renderSocialImage = (item: any) => {
        let socialImg;
        switch (item.type) {
            case "Facebook":
                socialImg = <img src={`${IMAGE_PATH}/fb_icon.png`} alt="Facebook" />;
                break;

            case "Instagram":
                socialImg = <img src={`${IMAGE_PATH}/insta_icon.png`} alt="Instagram" />;
                break;

            case "LinkedIn":
                socialImg = <img src={`${IMAGE_PATH}/linkedin_icon.png`} alt="LinkedIn" />;
                break;

            case "Twitter":
                socialImg = <img src={`${IMAGE_PATH}/tw_icon.png`} alt="Twitter" />;
                break;

            case "Skype":
                socialImg = <img src={`${IMAGE_PATH}/skype_icon.png`} alt="Skype" />;
                break;

            case "Whatsapp":
                socialImg = <img src={`${IMAGE_PATH}/whatsapp_icon.png`} alt="Whatsapp" />;
                break;

            default:
                break;
        }
        return socialImg;
    }
    return (
        <React.Fragment>
            <div className="section qrc_social_links">
                <ul className="qrc_social_links_list">
                    {
                        (data && data?.social_links) &&
                        data.social_links.map((item: ISocialType, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    {
                                        item.url &&
                                        <li key={index} className="qr_cc_card" style={{ background: data?.box_color, borderRadius: data.box_radius }}>
                                            {
                                                index === 0 ?
                                                    <div className={`qrc_heading ${data.template === "template2" ? 'template_heading_text' : ''}`}>
                                                        <h2 style={{color: data?.primary_text_color}}>{data.social_title}</h2>
                                                        <p style={{color: data?.secondary_text_color}}>{data.social_description}</p>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <a rel="nofollow noopener noreferrer social_link_a" href={renderURL(item.url)} target="_blank">
                                                <div className="qrc_social_icon">
                                                    {renderSocialImage(item)}
                                                </div>
                                                <div className="qrc_social_text">
                                                    <div className="qrc_social_text_heading" style={{color: data?.primary_text_color}}>{item.title || item.url}</div>
                                                    {
                                                        (item?.show_sub_title === 1 || item?.show_sub_title === true) &&
                                                        <div className="qrc_social_text_discription" style={{color: data?.secondary_text_color}}>{item.sub_title || ''}</div>
                                                    }
                                                </div>
                                                <div className="qrc_social_action">
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </li>
                                    }
                                </React.Fragment>
                            )
                        })
                    }

                </ul>
            </div>
        </React.Fragment>
    )
}
