// Website and API URL
// export const WEBSITE_LINK="http://68.183.92.60/qr-code";
// export const BASE_URL_API="http://68.183.92.60:9001";

// export const WEBSITE_LINK="http://68.183.92.60/qr-code";
// export const BASE_URL_API="http://127.0.0.1:8000";
// export const IMAGE_PATH="/images";

// export const WEBSITE_LINK:any="http://68.183.92.60:9092";
// export const BASE_URL_API="http://64.227.186.5:9091";
// export const IMAGE_PATH="/images";

export const WEBSITE_LINK:any="https://hkquicklinker.com";
export const BASE_URL_API="https://hkquicklinker.com/qrcodebackend/public";
export const IMAGE_PATH="/images";

// apis
export const PROFILE = "profiles";
export const BUSINESS_CARD = "businesscard";
export const IMAGE_UPLOAD = "image/store";