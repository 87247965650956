import { Api } from "./axios";

export const MasterAPICall = function (url: string, method: string, auth_token: string, payload: any = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
        if (method === "get") {
            await Api.get(url, {
                params: {
                    ...payload
                },
                headers: { Authorization: auth_token }
            })
                .then(({ data }: any) => {
                    if (data?.success === true) resolve(data);
                    else reject(data);
                })
                .catch((error: Error) => reject(error));
        } else {
            let axiosCall = Api.post;
            switch (method) {

                case "post":
                    axiosCall = Api.post
                    break;

                case "patch":
                    axiosCall = Api.patch
                    break;

                case "put":
                    axiosCall = Api.put
                    break;

                case "delete":
                    axiosCall = Api.delete
                    break;
                default:
                    axiosCall = Api.post
                    break;
            }

            await axiosCall(url, payload, {
                headers: {
                    Authorization: auth_token
                }
            })
                .then(({ data }: any) => {
                    if (data?.success === true) resolve(data);
                    else reject(data);
                })
                .catch((error: Error) => reject(error));
        }
    });
};
